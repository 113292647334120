.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.pointer {
  cursor: pointer;
}

.eye-icon {
  filter: invert(63%) sepia(89%) saturate(1%) hue-rotate(314deg) brightness(115%) contrast(89%);
}

.eye-icon:hover {
  filter: invert(59%) sepia(95%) saturate(7%) hue-rotate(94deg) brightness(97%) contrast(89%);
}

.error {
  background-color: rgba(237,76,120,.1)!important;
  color: #ED4C78;
}

.success {
  background-color: rgba(0,201,167,.1)!important;
  color: #00C987;
}

.veryLightGreyText {
  color: #b9b8b8;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
